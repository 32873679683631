import React, {useEffect, useState} from 'react'
import sessionManager from '../util/sessionManager'
import socketManager from '../util/socketManager'
import classNames from 'classnames'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import * as buttonStyles from '../styles/button.module.css'
import Card from 'react-bootstrap/Card'

export default function Learners() {
  const [global, setGlobal] = useState(sessionManager.getState())
  const dispatch = sessionManager.dispatch


  useEffect(function () {
    return sessionManager.subscribe(function () {
      setGlobal(sessionManager.getState())
    })
  }, [])


  function handleSignInClick() {
    dispatch({
      type: 'UI_LOGIN',
      data: {},
    })
  }
  // if (global.current_user_vuid) {
  //   navigate('/mentors-access')
  //   return null
  // }

  return (
    <main>
      <title>Learners</title>

      <NavBar global={global} dispatch={dispatch} />

      <div className="page-heading">
        <Container>
          <h1>Learners</h1>
        </Container>
      </div>

      <Container>
        <Row>
          <Col md={{ span: 7 }}>
            <p>CE-Oh! is a ten-week program that combines online learning with live, facilitator-led sessions. Participants team up and build their own simulated business with the help of mentors. Along the way, they hear from guest experts who educate and motivate.
            </p>

            <p>
              This blended learning curriculum is designed for unemployed and underemployed individuals looking for ways to build their portfolio of skills and gain economic traction. Whether you want to take college courses, enter the workforce, become an apprentice, or start your own business, CE-Oh! offer timeless skills for the modern worker.
            </p>
          </Col>
          <Col md={{ offset: 1 }}>
            <Card
              className='text-center'
            >
              <Card.Body>
                <p className='h5'>Already Enrolled?</p>
                <Button
                  type='button'
                  className={classNames(
                    buttonStyles.button,
                    buttonStyles.buttonPrimary,
                  )}
                  size="lg"
                  onClick={handleSignInClick}
                >
                  Sign In
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <div className='footer'>
        <Footer />
      </div>
    </main>
  );
}
